import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    $(this.targets.find("fileupload")).fileupload({
      add: (e, data) => {
        data.url = `/${this.modelRoute}/${this.modelId}/upload`
        data.submit()
      },
      done: (e, data) => {
        $('#uploads').html(data.result)
      },
    }).bind('fileuploadfail', (e, data) => {
      alert("Cannot upload image: " + data.errorThrown);
    })
  }

  get modelId() {
    return $(this.element).data('model')
  }

  get modelRoute() {
    return $(this.element).data('route')
  }
}