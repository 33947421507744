import {Controller} from "stimulus"

export default class extends Controller {
  static targets = [ "list" ]

  initialize() {
    this.listTargets.forEach((el) => {
      let type = this.modelType
      let sortableProps = {
        group: this.modelType,
        animation: 300,
        delay: 0,
        onUpdate(evt) {
          let itemEl = evt.item;  // dragged HTMLElement
          let from = evt.from;  // previous list
          let to = evt.to;      // new list
          if (evt.newIndex !== evt.oldIndex) {
            $.post(`/${type}/sort`, {
              id: itemEl.id,
              from: from.id,
              to: to.id,
              oldIndex: evt.oldIndex,
              newIndex: evt.newIndex
            });
          }
        },
        onAdd(evt) {
          let itemEl = evt.item;  // dragged HTMLElement
          let from = evt.from;  // previous list
          let to = evt.to;      // new list
          $.post(`/${type}/move`, {
            id: itemEl.id,
            from: from.id,
            to: to.id,
            oldIndex: evt.oldIndex,
            newIndex: evt.newIndex
          });
        }
      };
      Sortable.create(el, sortableProps);
    })
  }

  get modelType() {
    return $(this.element).data('type')
  }
}