import { Controller } from "stimulus"
import debounce from 'lodash/debounce'

export default class extends Controller {
  static targets = [ "form", "field", "textfield", "iframe" ]

  initialize() {
    this.fieldTargets.forEach((el, i) => {
      $(el).on('change', ()=> { this.autosave() })
    })
    this.textfieldTargets.forEach((el, i) => {
      $(el).on('keyup', debounce(() => this.autosave(), 1000, { maxWait: 2000}))
    })
  }

  autosave() {
    $.post($(this.formTarget).attr('action'), $(this.formTarget).serialize(), () => {
      this.iframeTarget.contentWindow.location.reload()
    })
  }
}