import { Controller } from "stimulus"
import axios from "axios"
import debounce from "lodash/debounce";

export default class extends Controller {
  static targets = [ "textfield" ]

  initialize() {
    this.textfieldTargets.forEach((el, i) => {
      $(el).on('keyup', debounce(() => this.autosave($(el)), 1000, { maxWait: 2000}))
    })
  }

  autosave(el) {
    let data = { 'image': {}}
    data['image'][$(el).data('field')] = el.val()
    axios.patch(`/${this.modelRoute}/${this.modelId}/images/${this.imageId}`, data)
        .catch((error) => alert(error));
  }

  remove() {
    let result = confirm("Are you sure?");
    if (result) {
      axios.delete(`/${this.modelRoute}/${this.modelId}/images/${this.imageId}`)
          .then((response) => $(this.element).parent().html(response.data))
          .catch((error) => alert(error));
    }
  }

  setAsMain() {
    axios.post(`/${this.modelRoute}/${this.modelId}/images/${this.imageId}/set_as_main`)
        .then((response) => $(this.element).parent().html(response.data))
        .catch((error) => alert(error));
  }

  get modelId() {
    return $(this.element).data('model')
  }

  get modelRoute() {
    return $(this.element).data('route')
  }

  get imageId() {
    return $(this.element).data('image')
  }
}